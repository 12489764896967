
export default {
  props: {
    article: {
      type: Object,
      required: true,
    },
    hideDate: {
      type: Boolean,
      default: false,
    },
    colourOverride: {
      type: [String, Boolean],
      default: false,
    },
    isLarge: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      shareActive: false,
    };
  },
  computed: {
    postType () {
      if (this.article.type) {
        return this.article.type === `post` ? `news` : this.article.type.replace(/_/g, ` `);
      }
      return false;
    },
    imagePosition () {
      return this.article.acf && this.article.acf.featured_image_position
        ? this.article.acf.featured_image_position : `center`;
    },
    excerptLength () {
      return this.$store.state.config.excerpt_lengths.news_card;
    },
    sortedCategories () {
      if (this.article.categories) {
        const categories = [...this.article.categories]
          .filter(category => category && category.slug !== `uncategorized`);
        if (categories.length) {
          if (categories.length === 1) {
            return categories;
          }
          // Ensure primary category is first
          const primaryCat = categories.find(cat => cat.primary);
          categories.splice(categories.indexOf(primaryCat), 1);
          categories.unshift(primaryCat);
          return categories;
        }
      }
      return false;
    },
  },
};
