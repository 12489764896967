
export default {
  props: {
    block: {
      type: Object,
      required: true,
    },
    textColour: {
      type: String,
      default: ``,
    },
    background: {
      type: [String, Boolean],
      default: false,
    },
  },
  data () {
    return {
      videos: null,
      activeIndex: 0,
    };
  },
  computed: {
    latestVideo () {
      return this.videos && this.videos.length ? this.videos[0] : null;
    },
  },
  mounted () {
    this.$nextTick(() => {
      this.getVideos();
    });
  },
  methods: {
    async getVideos () {
      const queryParams = {
        fields: `id,title,categories,date,image,slug,type,acf`,
        posts: {
          post_type: `video`,
          posts_per_page: this.block.number_of_videos,
          post_status: `publish`,
          orderby: `date`,
          order: `DESC`,
        },
        options: {
          image_size: `medium_large`,
        },
      };

      const request = await this.$getPosts(`/posts`, queryParams);
      if (request !== null) {
        this.videos = request;
      }
    },
  },
};
