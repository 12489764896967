import { render, staticRenderFns } from "./HomeRedTv.vue?vue&type=template&id=8775b14c&scoped=true&"
import script from "./HomeRedTv.vue?vue&type=script&lang=js&"
export * from "./HomeRedTv.vue?vue&type=script&lang=js&"
import style0 from "./HomeRedTv.vue?vue&type=style&index=0&id=8775b14c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8775b14c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ResponsiveImage: require('/src/components/Content/ResponsiveImage.vue').default,HeroContent: require('/src/components/Home/HomeRedTV/HeroContent.vue').default,RedTvSlider: require('/src/components/Home/HomeRedTV/RedTvSlider.vue').default,NewsCard: require('/src/components/Aggregator/Cards/NewsCard.vue').default})
