
export default {
  props: {
    post: {
      type: Object,
      required: true,
    },
    label: {
      type: String,
      default: ``,
    },
  },
  computed: {
    link () {
      return this.post.slug ? `${process.env.siteUrl}${this.post.slug}` : this.post.link;
    },
    title () {
      return this.getExcerpt(this.post.title, 20);
    },
  },
  methods: {
    socialShare (channel, title, link) {
      switch (channel) {
      case `twitter`:
        window.open(
          `//twitter.com/share?url=${encodeURIComponent(this.link)}&text=${encodeURIComponent(title)} via ${process.env.siteHandle}&twitterwindow`,
          `height=600, width=750, top=${
            window.offsetHeight / 2 - 225
          }, left=${
            window.offsetWidth / 2
          }, toolbar=0, location=0, menubar=0, directories=0, scrollbars=0`,
        );
        break;

      case `facebook`:
        window.open(
          `//www.facebook.com/sharer/sharer.php?u=${escape(this.link)}&t=${this.link}`,
          ``,
          `menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600`,
        );
        break;

      case `linkedin`:
        window.open(
          `//www.linkedin.com/shareArticle?url=${this.link}&title=${title}`,
        );
        break;

      case `whatsapp`:
        window.location.href = `whatsapp://send?text=${encodeURIComponent(
          title,
        )} - ${encodeURIComponent(this.link)}`;
        break;

      default:
        window.open(
          `mailto:?subject=${escape(this.link)}&body=${title}:${this.link}`,
          ``,
        );
      }
    },
  },
};
