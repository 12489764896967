
export default {
  props: {
    content: {
      type: Object,
      default: () => null,
    },
    label: {
      type: [String, Boolean],
      default: false,
    },
    buttonLabel: {
      type: [String, Boolean],
      default: false,
    },
  },
  computed: {
    date () {
      return this.getDate(this.content.date, `DD MMMM YYYY`);
    },
  },
};
