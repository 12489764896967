import { render, staticRenderFns } from "./RedTvSlider.vue?vue&type=template&id=a3016a2c&scoped=true&"
import script from "./RedTvSlider.vue?vue&type=script&lang=js&"
export * from "./RedTvSlider.vue?vue&type=script&lang=js&"
import style0 from "./RedTvSlider.vue?vue&type=style&index=0&id=a3016a2c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a3016a2c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VideoCard: require('/src/components/Home/HomeRedTV/VideoCard.vue').default,Slider: require('/src/components/Slider/Slider.vue').default,SliderNavigation: require('/src/components/Slider/SliderNavigation.vue').default})
