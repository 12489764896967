
export default {
  props: {
    block: {
      type: Object,
      required: true,
    },
    videos: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      carouselOptions: {
        slidesPerView: 4,
        slidesPerGroup: 1,
        spaceBetween: 30,
        centeredSlides: true,
        modules: `modules`,
        breakpoints: {
          1500: {
            slidesPerView: 4.5,
          },
          1750: {
            slidesPerView: 5,
          },
        },
      },
      carousel: null,
      activeIndex: 0,
    };
  },
  computed: {
    sliderName () {
      if (this.block.title) {
        return `carousel-${this.slugify(this.block.title)}`;
      }
      return `carousel-${Math.floor(Math.random() * 1000)}`;
    },
    videoItems () {
      return this.videos && this.videos.length ? this.videos.slice(1, this.videos.length) : null;
    },
  },
  methods: {
    indexChange (index) {
      this.activeIndex = index;
      const heroVideo = document.querySelector(`.redtv-latest`);

      if (this.activeIndex > 0) {
        // if slider has moved hide Hero Latest video
        heroVideo.classList.add(`hidden`);
      } else {
        heroVideo.classList.remove(`hidden`);
      };
    },
    nextSlide () {
      this.carousel.slideNext(500);
    },
    prevSlide () {
      this.carousel.slidePrev(500);
    },
  },
};
