
export default {
  props: {
    prevSlide: {
      type: Function,
      default: () => undefined,
    },
    nextSlide: {
      type: Function,
      default: () => undefined,
    },
    white: {
      type: Boolean,
      default: false,
    },
    commercial: {
      type: Boolean,
      default: false,
    },
    canNext: {
      type: Boolean,
      default: true,
    },
    canPrev: {
      type: Boolean,
      default: true,
    },
    showPagination: {
      type: Boolean,
      default: false,
    },
    currentSlide: {
      type: Number,
      default: 1,
    },
    totalSlides: {
      type: Number,
      default: 1,
    },
  },
};
