
export default {
  props: {
    video: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      titleWords: 10,
    };
  },
  computed: {
    date () {
      return this.getDate(this.video.date, `DD MMMM YYYY`);
    },
  },
  methods: {
    getThumbnail (video) {
      return video.image
        ? video.image.thumbnail
        : this.$store.state.config.default_page_image.sizes.thumbnail;
    },
    getTitle (video, titleWords) {
      const strippedTitle = video.title.replace(/(<([^>]+)>)/gi, ``);
      const titleArr = strippedTitle.split(` `);
      if (titleArr.length > titleWords) {
        return `${titleArr.slice(0, (titleWords - 1)).join(` `)}...`;
      }
      return strippedTitle;
    },
  },
};
